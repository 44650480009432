.section-page-break {
  background-color: $off-white;
  padding: 2rem 1rem;

  .section-page-break-container {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }

  h2 {
    font-size: 4rem;

    @media only screen and (max-width: $bp-common) {
      font-size: 3rem;
    }

    @media only screen and (max-width: $bp-tablet) {
      font-size: 2rem;
    }

    @media only screen and (max-width: $bp-smallish) {
      font-size: 1.75rem;
    }

    @media only screen and (max-width: $bp-small) {
      font-size: 1.5rem;
    }
  }

  a {
    color: $black;

    &:hover {
      color: $blue;
    }
  }
}
