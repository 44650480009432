.section-testimonials {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 1rem;

  h2 {
    text-align: center;

    @media only screen and (max-width: $bp-tablet) {
      font-size: 2rem;
    }

    @media only screen and (max-width: $bp-smallish) {
      font-size: 1.75rem;
    }

    @media only screen and (max-width: $bp-small) {
      font-size: 1.5rem;
    }
  }
}

.section-testimonials-container {
  margin: 0 auto;
  max-width: 1280px;
  min-height: 224px;
}

.section-testimonials-content {
  align-items: center;
  align-self: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  max-width: 75%;
  position: absolute;
  z-index: 10;

  h4 {
    font-family: $body-font-family;
    font-size: 2rem;
    font-weight: 400;

    @media only screen and (max-width: $bp-common) {
      font-size: 1.75rem;
    }

    @media only screen and (max-width: $bp-smallish) {
      font-size: 1.5rem;
    }

    @media only screen and (max-width: $bp-small) {
      font-size: 1.25rem;
    }

    @media only screen and (max-width: $bp-xss) {
      font-size: 1.125rem;
    }
  }

  h5 {
    font-size: 1.5rem;

    @media only screen and (max-width: $bp-common) {
      font-size: 1.25rem;
    }

    @media only screen and (max-width: $bp-smallish) {
      font-size: 1rem;
    }

    @media only screen and (max-width: $bp-xss) {
      font-size: 0.875rem;
    }
  }
}

#testimonials {
  height: 100%;
  max-height: 400px;
  overflow: hidden;
  position: relative;
  width: 100%;

  // @media (max-width: 768px) {
  //   width: 100%;
  // }

  // @media (max-width: 480px) {
  //   width: 100%;
  // }

  .animate {
    animation: animate 2s;
  }

  .slides {
    -webkit-animation: slide-over 1s;
    display: none;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .slides {
    .testimonials {
      height: 100%;
      width: 100%;
    }
  }

  .testimonials-nav {
    color: $black;
    cursor: pointer;
    font-size: 30px;
    padding: 10px;
    position: absolute;
    top: 50%;
    text-shadow: $white 0px 0px 1px, $white 0px 0px 1px, $white 0px 0px 1px,
      $white 0px 0px 1px, $white 0px 0px 1px, $white 0px 0px 1px;
    transform: translate(0, -50%);
    transition: ease-out 0.6s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 100000;

    &:hover {
      background: lighten($dark-grey, 40%);
      transition: ease-in 0.6s;
    }
  }

  .prev {
    left: 5px;
  }

  .next {
    right: 5px;
  }
}

.button-container {
  /* Comment out display to show nav button */
  display: none;
  margin: 19px auto;
  text-align: center;

  // @media (max-width: 480px) {
  //   min-width: 450px;
  // }

  .testimonials-radio-button {
    background: white;
    border-top: 1px inset;
    border-radius: 7.5px;
    cursor: pointer;
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:last-child {
      margin: 0;
    }
  }

  .selected {
    background: black;
  }
}

@-webkit-keyframes slide-over {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}
