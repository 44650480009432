.section-about {
  background-color: $white;
  padding: 6.5rem 1rem;

  @media only screen and (max-width: $bp-tablet) {
    padding: 5.5rem 1rem;
  }

  @media only screen and (max-width: $bp-smallish) {
    padding: 3.5rem 1rem;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 2.5rem 1rem;
  }

  .section-about-container {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }

  p {
    font-size: 3rem;
    font-weight: 700;

    @media only screen and (max-width: $bp-common) {
      font-size: 2rem;
    }

    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.5rem;
    }

    @media only screen and (max-width: $bp-smallish) {
      font-size: 1.25rem;
    }

    @media only screen and (max-width: $bp-small) {
      font-size: 1rem;
    }
  }
}
