// Type
// ----
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Work+Sans:800&display=swap");

$header-font-family: "Work Sans", sans-serif;
$body-font-family: "Roboto", sans-serif;
$base-font-size: 16px;
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  font-weight: 800;
}
h1 {
  font-size: 5rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 0.875rem;
}
p {
  font-family: $body-font-family;
  font-size: $base-font-size;
  line-height: normal;
  margin-bottom: 1rem;
  margin-top: 0;
}
ul {
  font-family: $body-font-family;
  line-height: normal;
}

// Colors
// ------
$white: #fefefe;
$black: #051017;
$off-white: #f5f2eb;
$red: #b80c09;
$blue: #384d9e;
$dark-grey: #2b3640;
$med-grey: #454554;
$grey: #bdbdbd;

// Breakpoints
// -----------
$bp-xss: 20em;
$bp-xs: 23.4375em;
$bp-small: 26.5625em;
$bp-smaller: 33.125em;
$bp-smallish: 40em;
$bp-tablet: 48em;
$bp-tablet-mid: 59.5em;
$bp-tablet-pro: 64em;
$bp-common: 85.375em;
$bp-common-up: 85.376em;
$bp-large: 100em;
$bp-xl: 120em;
