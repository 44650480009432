.section-hero {
  display: flex;
  height: 39.5vw;
  overflow: hidden;
  max-height: 764px;
  position: relative;
  justify-content: center;
  align-items: center;

  picture {
    bottom: 50%;
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 1;
  }

  .section-hero-text {
    background-image: linear-gradient(
      to right,
      #7b7b8d,
      #5d5d6e 35%,
      rgba(88, 88, 104, 0.25) 49%,
      rgba(69, 69, 84, 0)
    );
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: auto;
    margin-top: 0;
    z-index: 100;

    .section-hero-text-container {
      max-width: 1280px;
      margin: 0 auto;
      margin-bottom: 2rem;
      min-width: 1280px;
      padding: 1rem;

      @media only screen and (max-width: $bp-common) {
        margin-bottom: 1rem;
      }
      @media only screen and (max-width: $bp-smallish) {
        margin-bottom: 0;
      }
    }

    h1 {
      color: $white;
      margin: 0;

      @media only screen and (min-width: $bp-common-up) {
        max-width: 498px;
      }
      @media only screen and (min-width: $bp-large) {
        font-size: 6rem;
        max-width: 580px;
      }
      @media only screen and (max-width: $bp-common) {
        font-size: 4.5rem;
        max-width: 448px;
      }
      @media only screen and (max-width: $bp-tablet-pro) {
        font-size: 3.5rem;
        max-width: 330px;
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 2.5rem;
        max-width: 230px;
      }
      @media only screen and (max-width: $bp-smallish) {
        font-size: 2rem;
        max-width: 186px;
      }
      @media only screen and (max-width: $bp-smaller) {
        font-size: 1.5rem;
        max-width: 132px;
      }
      @media only screen and (max-width: $bp-xs) {
        font-size: 1.125rem;
        max-width: 98px;
      }
    }

    h3 {
      color: $white;
      font-family: $body-font-family;
      font-weight: 700;
      margin: 0;

      @media only screen and (max-width: $bp-tablet-pro) {
        font-size: 1.25rem;
      }
      @media only screen and (max-width: $bp-smallish) {
        font-size: 1.125rem;
      }
      @media only screen and (max-width: $bp-smaller) {
        font-size: 1rem;
      }
      @media only screen and (max-width: $bp-xs) {
        font-size: 0.875rem;
      }
    }
  }
}
