// Air Condtioning Service
.section-air-condition-service {
  justify-content: flex-end;
  display: flex;
  background-color: $white;

  .section-air-condition-service-background {
    background: linear-gradient(to left, $blue 90%, $white 90%);
    width: 100%;

    @media only screen and (max-width: $bp-smallish) {
      background: $blue;
    }
  }

  .section-air-condition-service-container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    justify-items: center;
    margin: 0 auto;
    max-width: 1280px;
    min-height: 375px;
    padding: 3rem 1rem;

    @media only screen and (max-width: $bp-tablet-pro) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .air-condition-service-text {
    color: $white;
    justify-self: flex-end;

    @media only screen and (max-width: $bp-tablet-pro) {
      justify-self: center;
    }

    h3 {
      font-size: 2.5rem;
      margin-bottom: 1.25rem;

      @media only screen and (max-width: $bp-common) {
        font-size: 2rem;
      }

      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.75rem;
      }

      @media only screen and (max-width: $bp-smallish) {
        font-size: 1.5rem;
      }
    }

    ul {
      font-size: 2rem;
      margin: 0;
      list-style-type: none;

      @media only screen and (max-width: $bp-common) {
        font-size: 1.5rem;
      }

      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.25rem;
      }

      @media only screen and (max-width: $bp-smallish) {
        font-size: 1.125rem;
      }
    }
  }

  .air-condition-service-icon {
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: $bp-tablet-pro) {
      justify-content: center;
    }

    img {
      @media only screen and (max-width: $bp-common) {
        max-width: 75%;
      }

      // @media only screen and (max-width: $bp-tablet-pro) {
      //   max-width: 100%;
      // }
    }
  }
}

// Heating Service
.section-heating-service {
  justify-content: flex-start;
  display: flex;
  background-color: $white;

  .section-heating-service-background {
    background: linear-gradient(to right, $red 90%, $white 90%);
    width: 100%;

    @media only screen and (max-width: $bp-smallish) {
      background: $red;
    }
  }

  .section-heating-service-container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    justify-items: center;
    margin: 0 auto;
    max-width: 1280px;
    min-height: 375px;
    padding: 3rem 1rem;

    @media only screen and (max-width: $bp-tablet-pro) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .heating-service-text {
    color: $white;
    justify-self: flex-start;

    @media only screen and (max-width: $bp-tablet-pro) {
      justify-self: center;
    }

    h3 {
      font-size: 2.5rem;
      margin-bottom: 1.25rem;

      @media only screen and (max-width: $bp-common) {
        font-size: 2rem;
      }

      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.75rem;
      }

      @media only screen and (max-width: $bp-smallish) {
        font-size: 1.5rem;
      }
    }

    ul {
      font-size: 2rem;
      list-style-type: none;
      margin: 0;

      @media only screen and (max-width: $bp-common) {
        font-size: 1.5rem;
      }

      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.25rem;
      }

      @media only screen and (max-width: $bp-smallish) {
        font-size: 1.125rem;
      }
    }
  }

  .heating-service-icon {
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: $bp-tablet-pro) {
      justify-content: center;
    }

    img {
      @media only screen and (max-width: $bp-common) {
        max-width: 75%;
      }

      // @media only screen and (max-width: $bp-tablet-pro) {
      //   max-width: 100%;
      // }
    }
  }
}
