header {
  background-color: $off-white;
  padding: 2rem 1rem;

  .top-bar {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    max-width: 1280px;

    img {
      max-width: 496px;
      width: 100%;

      @media only screen and (max-width: $bp-tablet-pro) {
        max-width: 440px;
      }
    }

    h2 {
      justify-self: end;
      margin: 0;

      @media only screen and (max-width: $bp-tablet-pro) {
        font-size: 2.5rem;
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 2rem;
      }
      @media only screen and (max-width: $bp-smallish) {
        font-size: 1.5rem;
      }
      @media only screen and (max-width: $bp-small) {
        font-size: 1rem;
      }
    }

    a {
      color: $black;

      &:hover {
        color: $red;
      }
    }
  }
}
