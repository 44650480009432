.section-pre-footer {
  background-color: $off-white;
  padding: 4rem 1rem;

  .section-pre-footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    justify-items: center;
    margin: 0 auto;
    max-width: 1280px;

    @media only screen and (max-width: $bp-smallish) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
  }

  .pre-footer-text {
    display: flex;
    flex-direction: column;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      @media only screen and (max-width: $bp-smallish) {
        min-width: 208px;
      }
    }

    h3 {
      margin-bottom: 1rem;
    }

    h5 {
      margin-bottom: 1rem;

      &:first-of-type {
        color: $med-grey;
      }
    }

    ul {
      line-height: 1.5;
      list-style-type: none;
      margin: 0;
      padding-left: 0;
    }

    a {
      color: $black;

      &:hover {
        color: $blue;
      }
    }
  }

  .hours-jon-icon {
    display: flex;

    @media only screen and (max-width: $bp-tablet-mid) {
      flex-direction: column;
    }

    img {
      margin-left: 1rem;
      max-width: 100px;

      @media only screen and (max-width: $bp-tablet-mid) {
        align-self: center;
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}
