.section-footer {
  background-color: $white;
  padding: 1rem;

  .section-footer-container {
    align-items: center;
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 75% 1fr;
    margin: 0 auto;
    max-width: 1280px;

    @media only screen and (max-width: $bp-smallish) {
      grid-template-columns: 1fr;
      text-align: center;
    }

    h6 {
      @media only screen and (max-width: $bp-smallish) {
        margin-bottom: 0.5rem;
      }
    }

    a {
      justify-self: end;

      @media only screen and (max-width: $bp-smallish) {
        justify-self: center;
        max-width: 72px;
      }

      img {
        max-width: 88px;
        width: 100%;

        @media only screen and (max-width: $bp-smallish) {
          max-width: 72px;
        }
      }
    }
  }
}
